import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import {staticRoutes,activeRoutes} from "@/router/index.js";

import api from '@/api'

Vue.use(Vuex)
Vue.use(VueRouter)

const store = new Vuex.Store({

	// 数据
	state: {
		//路由
		routes: [],
    addRoutes: [],
		host: 'https://www.mengxingdi.cn/lixin/',
		action:'https://www.mengxingdi.cn/lixin/api/uploadFile',
		token: "",
		userData: {},
		classData: [
      {name:'体验课',id:3},
      {name:'30分钟',id:1},
      {name:'60分钟',id:2},
    ],
    classData1: [
    	{name:'未开始',id:0},
    	{name:'进行中',id:1},
    	{name:'已完成',id:2},
    	{name:'已过期',id:3},
    ],//
    typeData:[
      {name:'有效',id:2},
      {name:'无效',id:1},
      {name:'未开始',id:0},
    ],
    payData:[
      {name:'微信',id:1},
      {name:'支付宝',id:2},
      {name:'学习币兑换',id:3},
    ],
    sexData:[
      {name:'男',id:1},
      {name:'女',id:2}
    ],
    khData:[
      {name:'已通过',id:2},
      {name:'未通过',id:1}
    ],
    jyData:[
      {name:'禁用',id:1},
      {name:'正常',id:0}
    ],
    // 分类管理
    kcData:[],//课程名称
		kechengData: [],// 课程类型
    bianData:[],//变动类型
		jigouData: [],// 机构列表
    scData:[
      {name:'30分钟',id:'1'},
      {name:'60分钟',id:'2'},
      {name:'90分钟',id:'3'},
      {name:'120分钟',id:'4'}
    ],//体验时长
		xueduanData: [],// 学段
		nanduData: [],// 难度
    jiaocaiData:[],//教材
    teacherList:[],//教练
    jfData:[],//交付方式
		pageSizes: [10, 20, 50, 100],
		defaultPageSize:10,
	},

	// 修改state
	mutations: {
		//路由
		SET_ROUTES(state,addRoutes){
            state.addRoutes = addRoutes;
            state.routes = staticRoutes.concat(addRoutes);
        },
		//token
		setToken(state,token) {
			state.token = token;
		},
		setUserData(state, data) {
			state.userData = data;
		},
		// 区域管理
		setRegionData(state, data) {
			state.regioData = data;
		},
		// 课程类型
		setClassData(state, data) {
			state.kechengData = data;
		},
		// 教练列表
		setTeacherData(state, data) {
			state.teacherList = data;
		},
		// 交付方式
		setJfData(state, data) {
			state.jfData = data;
		},
		// 学段
		setXueDuan(state, data) {
			state.xueduanData = data;
		},
		// 难度
		setnNanduData(state, data) {
			state.nanduData = data;
		},
    // 难度
    setJiaoData(state, data) {
    	state.jiaocaiData = data;
    },
    // 变动类型
    setbdtype(state, data) {
    	state.bianData = data;
    },
    // 机构列表
    setJigouData(state, data) {
    	state.jigouData = data;
    },
	},

	actions: {
		//路由  activeRoutes
		setRouterAsync({commit}){
      // console.log(activeRoutes[0],'最开始')
			return new Promise(reslove =>{
				api.role.menuList().then(res=>{
           // console.log(res,'路由菜单')
					if(res&&res.success){
						//角色对应的权限数据
            sessionStorage.setItem('permissions', JSON.stringify(res.permissions || '[]'))
            res.treeData.push({remarks:'Addty',type:1})
						var Data=res.treeData;
						var routes=activeRoutes;
            // console.log(activeRoutes[0],'最开始')
            // return
						routes[0].children=getMenuTree(activeRoutes[0].children,Data);
            // console.log(routes,'组合')
						commit("SET_ROUTES",routes);
						reslove();
					}
					function getMenuTree(menus,e){
						var children=[];
            // console.log(menus,e)
						for(var i=0;i<menus.length;i++){
							for(var j=0;j<e.length;j++){
								if(menus[i].name==e[j].remarks&&e[j].type<2){
									if(menus[i].children&&e[j].children){
                    // console.log(i,'----------',j)
										menus[i].children=getMenuTree(menus[i].children,e[j].children);
									}
									children.push(menus[i]);
								}
							}
						}
						return children;
					}

				})
			})
		},
		// 用户信息
		setUserInfo({ commit }) {
			api.login.info().then(res => {
        console.log(res,'用户信息')
				if (res.result == 0) {
					commit("setUserData", res.data)
				}
			})
		},
		// 课程类型
		setKcType({ commit }) {
			api.login.kcType().then(res => {
				if (res.result == 0) {
					commit("setClassData", res.dataList)
				}
			})
		},
		// 教练列表
		setTeacherList({ commit }) {
			api.login.teacherList().then(res => {
				if (res.result == 0) {
					commit("setTeacherData", res.dataList)
				}
			})
		},
		// 交付方式
		setJfType({ commit }) {
			api.login.jfType().then(res => {
				if (res.result == 0) {
					commit("setJfData", res.dataList)
				}
			})
		},
		// 学段
		setXueDuanAsync({ commit }) {
			api.login.xuedList().then(res => {
				if (res.result == 0) {
					commit("setXueDuan", res.dataList)
				}
			})
		},
		// 难度
		setNanDuAsync({ commit }) {
			api.login.nandList().then(res => {
				if (res.result == 0) {
					commit("setnNanduData", res.dataList)
				}
			})
		},
    // 教材
    setJiaocaiAsync({ commit }) {
    	api.login.jcList().then(res => {
    		if (res.result == 0) {
    			commit("setJiaoData", res.dataList)
    		}
    	})
    },
    // 变动类型
    setBdTypeAsync({ commit }) {
    	api.login.bdType().then(res => {
    		if (res.result == 0) {
    			commit("setbdtype", res.dataList)
    		}
    	})
    },
    // 机构列表
    setJgList({ commit }) {
      let data={
        pageSize:100,
        pageNo:1,
      }
    	api.sk.jgList(data).then(res=>{
    	    if(res.result==0){
    	      commit("setJigouData", res.dataList)
    	    }
    	});
    },
	},

	modules: {

	},

	// 计算
	getter: {

	},

	plugins: [
		createPersistedState({
			reducer(state) {
				return {
					token: state.token,
					userData: state.userData

				}
			}
		})
	]
})
export default store;
