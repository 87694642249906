import path from "./path"
import axios from "./axios"

export default {
    yuList(data) {
        return axios.post(path.yuList, data);
    },
    createYu(data) {
        return axios.post(path.createYu,data)
    },
    editYu(data) {
        return axios.post(path.editYu,data)
    },
    delYu(data) {
        return axios.post(path.delYu,data)
    },
    skList(data) {
        return axios.post(path.skList,data)
    },
    testList(data) {
        return axios.post(path.testList,data)
    },
    jgxsList(data) {
        return axios.post(path.jgxsList,data)
    },
    tenantList(data) {
        return axios.post(path.tenantList,data)
    },
    // 抗遗忘
    editKyw(data) {
        return axios.post(path.editKyw, data)
    },
    delKyw(data) {
        return axios.post(path.delKyw, data)
    },
    kywList(data) {
        return axios.post(path.kywList, data)
    },
    consumRecord(data) {
        return axios.post(path.consumRecord,data)
    },
    recharge(data) {
        return axios.post(path.recharge,data)
    },
    tuifei(data) {
        return axios.post(path.tuifei,data)
    },
    priceList(data) {
        return axios.post(path.priceList,data)
    },
    tsList(data){
      return axios.post(path.tsList,data)
    },
    delTs(data){
      return axios.post(path.delTs,data)
    },
    editPrice(data){
      return axios.post(path.editPrice,data)
    },
    codeList(data){
      return axios.post(path.codeList,data)
    },
    editCode(data){
      return axios.post(path.editCode,data)
    },
    deleteCode(data){
      return axios.post(path.deleteCode,data)
    },
    kcList(data){
      return axios.post(path.kcList,data)
    },
    jgList(data) {
        return axios.post(path.jgList,data)
    },
    editTenant(data) {
        return axios.post(path.editTenant,data)
    },
    jgJinyong(data) {
        return axios.post(path.jgJinyong,data)
    },
    jgChongzhi(data) {
        return axios.post(path.jgChongzhi,data)
    },
    jgKouchu(data) {
        return axios.post(path.jgKouchu,data)
    },
    jgCzList(data) {
        return axios.post(path.jgCzList,data)
    },
    editStaus(data){
      return axios.post(path.editStaus,data)
    },
    ktClass(data) {
        return axios.post(path.ktClass,data)
    },
    addMember(data) {
        return axios.post(path.addMember,data)
    },
    studentDetail(data) {
        return axios.post(path.studentDetail,data)
    },
    jyTeacher(data) {
        return axios.post(path.jyTeacher,data)
    },
    csList(data) {
        return axios.post(path.csList,data)
    },
    ckList(data) {
        return axios.post(path.ckList,data)
    },
}
