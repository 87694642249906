import moment from 'moment'
export default {
    install(Vue) {
        Vue.filter('date',function (val="", format = "yyyy-MM-dd") {
            if(!val){
                return false;
            }
            if(val.toString().search(/-|\//)==-1){
                val=val.toString().padEnd(13,0)*1;
            }
            var date = new Date(val);
            if (/(y+)/.test(format)) {
                format = format
                .replace(RegExp.$1, date.getFullYear() + "")
                .slice(4 - RegExp.$1.length);
            }
            let o = {
                "M+": date.getMonth() + 1,
                "d+": date.getDate(),
                "h+": date.getHours(),
                "m+": date.getMinutes(),
                "s+": date.getSeconds(),
            };
            for (var i in o) {
                if (new RegExp(`(${i})`).test(format)) {
                    let str = o[i] + "";
                    format = format.replace(
                        RegExp.$1,
                        RegExp.$1.length === 1 ? str : ("0" + str).slice(-2)
                    );
                }
            }
            return format;
        })
        Vue.filter('money',function (value = "0", currencyType = "￥", limit = 2) {
            let res;
            value *= 1;
            value = value.toFixed(limit);
            let prev = value.toString().split(".")[0]; //获取整数部分
            let next = value.toString().split(".")[1];
            res = prev.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "." + next;
            return currencyType + res;
        })
		Vue.filter('DateTime', function (value) {
		  return moment(value).format('YYYY-MM-DD HH:mm')
		})
		Vue.filter('hourTime', function (value) {
		  return moment(value).format('HH:mm')
		})
    }
}
