/*

路径管理：修改维护接口地址
对路径进行集中管理
path={login,company,dapart......}

*/
const mode = "development";
var host = "",host1='';

if (mode === 'development') {
    // host1 = "http://120.55.64.145/lixin/api/"
    host = "https://www.mengxingdi.cn/lixin/api-pc/"
} else {
    host = "https://www.mengxingdi.cn/lixin/"
}

export default {
    // 数据看板
    sjBoards:host+'dl/dataDashboards',
    dlTips:host+'dl/tips',
    // 登录
    login: host + 'sys/login',
    // 验证码
    getCode:host+'sys/getCode',
    // 短信验证码
    sendSms:host+'sendSms',
    // 修改密码
    editPwd:host+'jg/resetPassword',
    //预约列表
    yuList:host+"jg/classSchedule",
    //创建预约
    createYu:host+"jg/createYuYue",
    // 编辑课程预约
    editYu:host+'jg/editClassSchedule',
    // 删除课程预约
    delYu:host+'jg/deleteClassSchedule',
    //上课列表
    skList:host+"jg/classRecord",
    //测试记录
    testList:host+"jg/testRecord",
    // 课时消除记录
    consumRecord:host+'jg/consumRecord',
    // 词库管理
    ckList:host+'jg/tck',
    // 测试词库
    csList:host+'jg/testCk',
    // 充值
    recharge:host+"jg/studentTopUp",
    // 退费
    tuifei:host+"jg/studentRefund",
    // 课时价格
    priceList:host+'jg/price',
    //投诉列表
    tsList:host+"jg/feedback",
    // 投诉删除
    delTs:host+'jg/feedbackDelete',
    // 修改价格
    editPrice:host+"jg/editPrice",
    // 验证码管理
    codeList:host+"jg/code",
    // 添加验证码
    editCode:host+"jg/editCode",
    // 删除验证码
    deleteCode:host+'jg/deleteCode',
    // 个人信息
    info:host+'jg/info',
    // 公用
    // 课程类型
    kcType:host+'jg/kcType',
    // 教练列表
    teacherList:host+'jg/teacherList',
    // 教练搜索
    teacherListSelect:host+'jg/teacherListSelect',
    // 教练禁用
    jyTeacher:host+'jg/editTeacherStatus',
    // 学生列表
    xsList:host+'jg/memberList',
    // 交付方式
    jfType:host+'jg/jfType',
    // 学段
    xuedList:host+'jg/kcxd',
    // 难度
    nandList:host+'jg/kcLevel',
    // 教材列表
    jcList:host+'jg/jcList',
    // 变动类型
    bdType:host+'jg/bdType',
    // 根据时间查找日期
    searchDate:host+'jg/timeInterval',
    // 搜索课程
    kcList:host+'teacher/kcList',
    // 机构列表
    jgList:host+'dl/listTenant',
    // 机构学生管理
    jgxsList:host+'dl/memberList',
    // 代理下所有机构
    tenantList:host+'dl/tenantList',
    // 添加机构
    editTenant:host+'dl/editTenant',
    // 机构禁用
    jgJinyong:host+'dl/statusTenant',
    // 机构充值
    jgChongzhi:host+'dl/classRecharge',
    // 机构扣除
    jgKouchu:host+'dl/classDeduction',
    // 机构充值记录
    jgCzList:host+'dl/classRechargeRecord',
    // 学生禁用正常
    editStaus:host+'jg/editMemberStatus',
    // 学生  开通课程
    ktClass:host+'jg/ktClass',
    // 添加学员
    addMember:host+'jg/editMember',
    // 学生详情
    studentDetail:host+'jg/studentDetail',
    // 角色相关
    myRole:host+'sys/role/list',
    // 添加角色
    addRole:host+'sys/role/save',
    // 删除角色
    delRole:host+'sys/role/delete',
    // 账户列表
    userList:host+'sys/user/list',
    // 添加用户
    addUser:host+'sys/user/save',
    // 删除用户
    delUser:host+'sys/user/delete',
    // 菜单列表
    menuList:host+'sys/menu/treeData2',
    // 角色授权
    treeData:host+'sys/menu/treeData',
    // 备课
    bkList:host+'teacher/wordList',
    // 联系我们
    edtiUs:host+'jg/editContactUs',
    contactUs:host+'/jg/contactUs',
    // 间隔时间
    jgTimeList:host+'jg/kcJg',
    editInfo:host+'jg/editInfo',
    // 导出
    exportCs:host+'jg/testRecord/export',
    exportSk:host+'jg/classRecord/export',
    exportYy:host+'jg/classSchedule/export',
    // 测试报告
    csBaogao:host+'teacher/testRepost',
    // 编辑抗遗忘
    editKyw:host+'teacher/editKyw',
    // 删除抗遗忘
    delKyw:host+'teacher/deleteKyw',
    // 抗遗忘列表
    kywList:host+'teacher/listKyw',
    printKyw:host+'teacher/printKyw',
}
