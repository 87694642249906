<template>
    <div class="login-container">
        <div class="login flex ac">
			<img class="left" src="../assets/images/login-bg1.png" alt="" />
            <div class="right">
				<div class="tabs flex ac">
					<div class="f22 c6 fw li" v-for="item,index in list" :key="index" :class="current==index?'active':''" @click="clickChange(index)">{{item.name}}</div>
				</div>
				<el-form :model="loginData" :rules="rules" ref="loginData">
				    <el-form-item prop="userName">
						<div class="flex ac top">
							<img class="tel" src="../assets/images/tel.png" alt="" />
							<div class="f20 c4 fw">账号</div>
						</div>
				        <div class="con">
							<el-input v-model="loginData.userName" placeholder="请输入登录账号"></el-input>
						</div>
				    </el-form-item>
				    <el-form-item prop="password">
						<div class="flex ac top">
							<img class="tel" src="../assets/images/pwd.png" alt="" />
							<div class="f20 c4 fw">密码</div>
						</div>
						<div class="con">
							<el-input type="password" v-model="loginData.password" placeholder="请输入登录密码"></el-input>
						</div>
				    </el-form-item>
					<!-- <el-form-item prop="code">
						<div class="flex ac top">
							<img class="tel" src="../assets/images/code.png" alt="" />
							<div class="f20 c4 fw">验证码</div>
						</div>
					    <div class="con">
                <el-row :gutter="10">
                    <el-col :span="18">
                      <el-input v-model="loginData.code" max="4" placeholder="请输入验证码"></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-image @click="getCaptcha" :src="captchaPath" style="height:60px"></el-image>
                    </el-col>
                </el-row>
						</div>
					</el-form-item> -->
				    <el-form-item>
				        <div class="btn f20 cf fw flex ac jc" @click="submitForm('loginData')">登录</div>
				    </el-form-item>
				</el-form>
				<div class="bom flex ac sb f18">
					<div class="c6"><el-switch v-model="isJz"></el-switch><span style="margin-left: 10px;">记住密码</span></div>
					<div class="c1" @click="goTo()">忘记密码?</div>
				</div>
			</div>
        </div>
    </div>
</template>
<style lang="less" scoped>
.login-container{
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../assets/images/login-bg.png") no-repeat center center/cover;
    overflow: hidden;
    .login{
        width: 1373px;
        height: 835px;
        position: absolute;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        top: 50%;
        margin: auto;
        background: #fff;
        box-sizing: border-box;
        border-radius: 10px;
		.left{
			width: 673px;
			height: 835px;
		}
		.right{
			width: calc(100% - 673px);
			height: 835px;
			padding: 57px 70px 37px;
			box-sizing: border-box;
			.tabs{
				margin-bottom: 48px;
				.li:first-child{
					margin-right: 80px;
				}
				.li.active{
					color: #333;
					position: relative;
				}
				.li.active::after{
					content: '';
					display: block;
					width: 68px;
					height: 2px;
					background: #3979FA;
					position: absolute;
					bottom: -18px;
					left: 50%;
					transform: translateX(-50%);
				}
			}
			.top{
				margin-top: 20px;
			}
			.btn{
				margin-bottom: 20px;
			}
		}
        .title{
            margin-bottom: 30px;
            text-align: center;
            img{
                height: 80px;
            }
            h3{
                font-size: 24px;
                color: #409EFF;
                margin-top: 20px;
            }
        }
		.el-form{
			.tel{
				width: 25px;
				height: 31px;
				margin-right: 25px;
			}
		}
        .el-form .el-button{
            display: block;
            width: 100%;
        }
    }
}
</style>
<script>

export default {
    data(){
        return {
            loginData:{
              userName:"",
              password:""
              // code:""
            },
            captchaPath:'',
            isJz:false,
            list:[
              {name:'密码登录'},
              // {name:'手机号登录'},
            ],
            code:'',
            current:1,
            rules:{
                userName:[
                    {
                        required:true,
                        message:"请输入用户名",
                        trigger:"blur"
                    }
                ],
                password:[
                    {
                        required:true,
                        message:"请输入密码",
                        trigger:"blur"
                    }
                ],
                code:[
                    {
                        required:true,
                        message:"请输入验证码",
                        trigger:"blur"
                    }
                ]
            }
        }
    },
    mounted(){
      if(localStorage.getItem('user')){
        var user=JSON.parse(localStorage.getItem('user'))
        this.loginData.userName=user.userName
        this.loginData.password=user.password
        this.isJz=true
      }
      console.log('请求')
      // this.getCaptcha()
    },
    methods: {
      // 获取验证码
        getCaptcha () {
          console.log('请求')
          this.loginData.code = ''
          this.api.login.getCode().then(res=>{
            console.log(res,'验证码')
             // this.captchaPath = window.URL.createObjectURL(res);
             this.captchaPath='data:image/png;base64,'+res.base64
             this.code=res.code
          })
        },
        clickChange(index){
          // this.current=index
        },
        goTo(){
          console.log(this.$router)
          this.$router.push({path:'/forgot'})
        },
        submitForm(loginData) {
            this.$refs[loginData].validate((valid) => {
                if (valid) {
                    var s=this.toQueryString(this.loginData)
                    console.log(s)
                    // if(this.code!=this.loginData.code){
                    //   return this.$message.error('验证码错误')
                    // }
                    this.api.login.login(this.loginData).then(res=>{
                        // console.log(res);
                        if(res&&res.success){
                            // 存储token、data
                            if(this.isJz){
                              localStorage.setItem('user', JSON.stringify({userName:this.loginData.userName,password:this.loginData.password}));
                            }else{
                              localStorage.removeItem('user')
                            }
                            this.$store.commit("setToken",res.token);
                            this.$store.dispatch("setUserInfo");
                            this.$store.dispatch('setRouterAsync').then(()=>{
                                this.$router.options.routes=this.$store.state.routes;
                                this.$router.addRoutes(this.$store.state.addRoutes);
                                var menus = this.$router.options.routes[2].children
                                console.log(this.$store.state.routes,this.$router,'设置路由')
                                this.$router.push({name:menus[0].name});
                            })
                            this.$message({
                                message: '欢迎登录英语教学管理系统',
                                type: 'success'
                            });
                        }else{
                            this.$message({
                                message: res.msg,
                                type: 'error'
                            })
                        }
                    })

                } else {
                    return false;
                }
            });
        },
        resetForm(loginData) {
            this.$refs[loginData].resetFields();
        },

        toQueryString(params) {
            return Object.keys(params)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
                .join('&');
        }
    },
}
</script>
