import Vue from 'vue'
import VueRouter from 'vue-router'


import Forgot from "@/views/Forgot.vue"
import Login from "@/views/Login.vue"

import store from "@/store";
const originalPush = VueRouter.prototype.push
  VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
//静态路由
export const staticRoutes=[
	{
		path: '/',
		name: 'Login',
		component: Login
	},
  {
  	path: '/forgot',
  	name: 'Forgot',
  	component: Forgot
  }
	// {
	// 	path: '*',
	// 	name: 'Notfound',
	// 	component: () => import('@/views/Notfound.vue')
 //  },
]
//动态路由
export const activeRoutes=[
  {
      path: '/main',
      name: 'Main',
      meta:{title:"主页"},
      component: () => import('@/views/Main.vue'),
      children:[
        {
            path: '',
            name: 'Home',
            component: () => import('@/views/Home.vue'),
            meta:{icon:"el-icon-shujutongji",nameindex:'Home',title:"数据看板",isshow:true}
        },
        {
            path: '/jigou',
            name: 'Jigou',
            component: () => import('@/views/Jigou.vue'),
            meta:{icon:"el-icon-jigouguanli",nameindex:'Jigou',title:"机构管理",isshow:true}
        },
        {
            path: '/jigouStudent',
            name: 'JigouStudent',
            component: () => import('@/views/jigouStudent.vue'),
            meta:{icon:"el-icon-xueshengguanli2",nameindex:'jigouStudent',title:"机构学生管理",isshow:true}
        },
        {
            path: '/yuyue',
            name: 'Yuyue',
            component: () => import('@/views/Yuyue.vue'),
            meta:{icon:"el-icon-zhishiguanli",nameindex:'Yuyue',title:"预约课程",isshow:true},
        },
        {
            path: '/yuyuety',
            name: 'Yuyuety',
            component: () => import('@/views/Yuyuety.vue'),
            meta:{icon:"el-icon-zhishiguanli",nameindex:'Yuyuety',title:"预约体验课程",isshow:true},
        },
        {
            path: '/addkc',
            name: 'Addkc',
            component: () => import('@/views/kc/Addkc.vue'),
            meta:{icon:"el-icon-shouye",nameindex:'Yuyue',title:"创建课程",isshow:false}
        },
        {
            path: '/addty',
            name: 'Addty',
            component: () => import('@/views/kc/Addty.vue'),
            meta:{icon:"el-icon-shouye",nameindex:'Yuyuety',title:"创建课程",isshow:false}
        },
        {
            path: '/record',
            name: 'Record',
            component: () => import('@/views/Record.vue'),
            meta:{icon:"el-icon-caidan-cikuguanli",nameindex:'Record',title:"销课记录",isshow:true},
            children:[
              {
                path: '/sklist',
                name: 'Sklist',
                component: () => import('@/views/record/Sklist.vue'),
                meta:{icon:"",nameindex:'Sklist',title:"上课记录",isshow:true},
              },
              {
              	path: '/kangyw',
              	name: 'Kangyw',
              	meta:{icon:'',nameindex:'Kangyw',title:"21天抗遗忘",isshow:true},
              	component: () => import('@/views/record/Kangyw.vue'),
              },
              {
                path: '/cslist',
                name: 'Cslist',
                component: () => import('@/views/record/Cslist.vue'),
                meta:{icon:"",nameindex:'Cslist',title:"测试记录",isshow:true},
              },
              {
                path: '/xklist',
                name: 'Xklist',
                component: () => import('@/views/record/Xklist.vue'),
                meta:{icon:"",nameindex:'Xklist',title:"课时销除记录",isshow:true},
              }
            ]
        },
        {
            path: '/student',
            name: 'Student',
            component: () => import('@/views/Student.vue'),
            meta:{icon:"el-icon-xueshengguanli2",nameindex:'Student',title:"学生管理",isshow:true}
        },
        {
            path: '/jiaol',
            name: 'Jiaol',
            component: () => import('@/views/Jiaol.vue'),
            meta:{icon:"el-icon-jiaowuguanli",nameindex:'Jiaol',title:"教练管理",isshow:true}
        },
        {
            path: '/wordgl',
            name: 'Wordgl',
            component: () => import('@/views/Wordgl.vue'),
            meta:{icon:"el-icon-ciku",nameindex:'Wordgl',title:"词库管理",isshow:true}
        },
        {
            path: '/csword',
            name: 'Csword',
            component: () => import('@/views/Csword.vue'),
            meta:{icon:"el-icon-task-1212",nameindex:'Csword',title:"测试词库",isshow:true}
        },
        {
            path: '/tousu',
            name: 'Tousu',
            component: () => import('@/views/Tousu.vue'),
            meta:{icon:"el-icon-biaodan",nameindex:'Tousu',title:"投诉列表",isshow:true}
        },
        {
            path: '/ksprice',
            name: 'Ksprice',
            component: () => import('@/views/Ksprice.vue'),
            meta:{icon:"el-icon-zhangdanguanli",nameindex:'Ksprice',title:"课时价格管理",isshow:true}
        },
        {
            path: '/codelist',
            name: 'Codelist',
            component: () => import('@/views/Codelist.vue'),
            meta:{icon:"el-icon-yanzhengma",nameindex:'Codelist',title:"验证码管理",isshow:true}
        },
        {
            path: '/concat',
            name: 'Concat',
            component: () => import('@/views/Concat.vue'),
            meta:{icon:"el-icon-lianxi",nameindex:'Concat',title:"联系我们",isshow:true}
        },
        {
            path: '/myinfo',
            name: 'Myinfo',
            component: () => import('@/views/Myinfo.vue'),
            meta:{icon:"el-icon-gerenxinxi",nameindex:'Myinfo',title:"个人信息",isshow:true}
        },
        {
            path: '/qxgl',
            name: 'Qxgl',
            component: () => import('@/views/Qxgl.vue'),
            meta:{icon:"el-icon-jiaosequanxianguanli",nameindex:'Qxgl',title:"权限管理",isshow:true},
            children:[
              {
                path: '',
                name: 'Account',
                component: () => import('@/views/quanxian/Account.vue'),
                meta:{icon:"",nameindex:'Account',title:"账户权限管理",isshow:true},
              },
              {
                path: '/juese',
                name: 'Juese',
                component: () => import('@/views/quanxian/Juese.vue'),
                meta:{icon:"",nameindex:'Juese',title:"角色权限管理",isshow:true},
              },
              {
                path: '/dayin',
                name: 'Dayin',
                component: () => import('@/views/kc/Dayin.vue'),
                meta:{icon:"",nameindex:'Dayin',title:"打印",isshow:false},
              }
            ]
        },
      ]
    },
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes:staticRoutes
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
	// console.log(to, from, next);
	if (to.name === "Login") {
		if (store.state.token) {
			next({ name:"Home"})
		}
		next();
	}else if(to.name==='Forgot'){
    // if (store.state.token) {
    // 	next({ name:"Home"})
    // }
    next();
  } else {
		if (store.state.token) {
			//获取角色对应的权限
			next();
		} else {
			router.push({name:'Login'})
		}
	}
})




export default router



