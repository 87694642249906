/*

总入口：集中所有模块，放置到一个对象中，统一访问
api ＝ {模块1，模块2，模块3......，模块n}

*/
// 登录
import login from "./login";
// 图片上传
import upload from "./upload";
// 上课相关
import sk from "./sk";
import role from "./role";

export default {
    // 登录
    login,
    // 公司
    upload,
    // 上课相关
    sk,
    // 角色
    role
}

