import path from "./path";
import axios from "./axios";

export default {

    myRole(data) {
        return axios.get(path.myRole, {params:data})
    },
    addRole(data) {
        return axios.post(path.addRole, data)
    },
    delRole(data) {
        return axios.post(path.delRole, data)
    },
    userList(data) {
        return axios.get(path.userList, {params:data})
    },
    addUser(data) {
        return axios.post(path.addUser, data)
    },
    delUser(data) {
        return axios.post(path.delUser, data)
    },
    menuList(term={}) {
        return axios.get(path.menuList)
    },
    treeData(data) {
        return axios.post(path.treeData, data)
    },

}
