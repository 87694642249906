export const money = (rule, value, callback) => {
  if (!value) {
      return callback(new Error('金额不能为空'));
    }
    if (!/^(\d+|\d+.\d{1,2})$/.test(value)) {
      callback(new Error('请输入正确的金额格式'));
    } else {
      callback();
    }
}
