import path from "./path"
import axios from "./axios"

export default {
    login(data) {
        return axios.post(path.login, data);
    },
    editPwd(data) {
        return axios.post(path.editPwd, data);
    },
    sendSms(data) {
        return axios.post(path.sendSms, data);
    },
    getCode(term={}) {
        return axios.get(path.getCode);
    },
    info(term={}) {
        return axios.post(path.info)
    },
    editInfo(data) {
        return axios.post(path.editInfo, data);
    },
    kcType(term={}) {
        return axios.post(path.kcType)
    },
    teacherList(data) {
        return axios.post(path.teacherList,data)
    },
    teacherListSelect(data) {
        return axios.post(path.teacherListSelect,data)
    },
    xsList(data) {
        return axios.post(path.xsList,data)
    },
    jfType(term={}) {
        return axios.post(path.jfType)
    },
    bdType(term={}) {
        return axios.post(path.bdType)
    },
    searchDate(data) {
        return axios.post(path.searchDate,data)
    },
    xuedList(term={}) {
        return axios.post(path.xuedList)
    },
    nandList(term={}) {
        return axios.post(path.nandList)
    },
    jcList(term={}) {
        return axios.post(path.jcList)
    },
    bkList(data){
        return axios.post(path.bkList,data)
    },
    edtiUs(data){
        return axios.post(path.edtiUs,data)
    },
    contactUs(data){
        return axios.post(path.contactUs,data)
    },
    jgTimeList(data){
        return axios.post(path.jgTimeList,data)
    },
    // 导出
    exportCs(data){
        return axios.post(path.exportCs,data,{responseType: 'blob'})
    },
    exportSk(data){
        return axios.post(path.exportSk,data,{responseType: 'blob'})
    },
    exportYy(data){
        return axios.post(path.exportYy,data,{responseType: 'blob'})
    },
    csBaogao(data) {
        return axios.post(path.csBaogao, data);
    },
    // 数据看板
    sjBoards(data) {
        return axios.post(path.sjBoards, data);
    },
    dlTips(data) {
        return axios.post(path.dlTips, data);
    },
    printKyw(data){
        return axios.post(path.printKyw, data);
    },
}
