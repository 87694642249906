<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);

    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}
export default {
	mounted(){
		if(this.$route.name!=='Login'){
			if(this.$store.state.addRoutes.length>0){
				this.$router.options.routes=this.$store.state.routes;
				// this.$router.addRoutes(this.$store.state.addRoutes);
			}else{
				this.$store.dispatch('setRouterAsync').then(()=>{
					this.$router.options.routes=this.$store.state.routes;
					this.$router.addRoutes(this.$store.state.addRoutes);
				})
			}
		}
	},
	updated(){
		if(this.$route.name!=='Login'){
			if(this.$store.state.addRoutes.length>0){
				this.$router.options.routes=this.$store.state.routes;
				// this.$router.addRoutes(this.$store.state.addRoutes);
			}else{
				this.$store.dispatch('setRouterAsync').then(()=>{
					this.$router.options.routes=this.$store.state.routes;
					this.$router.addRoutes(this.$store.state.addRoutes);
				})
			}
		}
	}
}
</script>

<style lang="less">
// scroll-view ::-webkit-scrollbar {
// 		display: none !important;
// 		width: 0 !important;
// 		height: 0 !important;
// 		-webkit-appearance: none;
// 		background: transparent;
// 	}
// 	::-webkit-scrollbar {
// 		display: none;
// 		width: 0 !important;
// 		height: 0 !important;
// 		-webkit-appearance: none;
// 		background: transparent;
// 		color: transparent;
// 	}
</style>
